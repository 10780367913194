import OADs from "./components/modules/oads";
import Users from "./components/modules/users";

const navigation = [
  { title: "Gestion OADs", slug: "oads", Module: OADs },
  { title: "Utilisateurs", slug: "utilisateurs", Module: Users, superadmin: true }
];

export const getNavigation = (isSuperAdmin) =>
  isSuperAdmin ? navigation : navigation.filter((entry) => !entry.superadmin);
