import * as React from "react";
import { useState, createContext, useContext } from "react";
import { Fade, Modal, Snackbar, makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Loader from "../components/loader";

const useStyles = makeStyles(() => {
  return {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  };
});

const AdminContext = createContext();

export const useAdminContext = () => {
  const context = useContext(AdminContext);
  if (!context) throw new Error(`useAdminContext must be used within a AdminContextProvider`);
  return context;
};

export const AdminContextProvider = (props) => {
  const [loader, setLoader] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false });
  const [modal, setModal] = useState({ open: false, children: <></> });
  const classes = useStyles();

  const defaultSnackbarProps = {
    message: "",
    onClose: () => setSnackbar((snackbar) => ({ open: false })),
    autoHideDuration: 5000,
    TransitionComponent: Fade,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    }
  };

  const defaultModalProps = {
    onClose: () => setModal((modal) => ({ open: false })),
    disableAutoFocus: true
  };

  const triggerError = (message) =>
    setSnackbar({
      message,
      open: true,
      severity: "error"
    });

  const triggerSuccess = (message) =>
    setSnackbar({
      message,
      open: true,
      severity: "success"
    });

  const openModal = (children) =>
    setModal({
      open: true,
      children,
      className: classes.modal
    });

  const closeModal = () => setModal({ open: false });

  return (
    <AdminContext.Provider
      value={{
        loader,
        setLoader,
        snackbar,
        setSnackbar,
        modal,
        setModal,
        openModal,
        closeModal,
        triggerError,
        triggerSuccess
      }}
      {...props}
    >
      {props.children}
      <Snackbar key={snackbar.message} {...defaultSnackbarProps} {...snackbar}>
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
      <Modal {...defaultModalProps} {...modal}>
        {modal.children}
      </Modal>
      {loader && <Loader />}
    </AdminContext.Provider>
  );
};
