import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#94c121"
    },
    secondary: {
      main: "#87693c"
    },
    components: {
      blue: {
        light: "#B9E4E4",
        main: "#7CCACC",
        dark: "#077375"
      },
      orange: "#EC9477",
      rose: "#ED88AE",
      beige: "#DBC7B7"
    },
    theming: {
      grey: {
        extralight: "#F6F6F6",
        light: "#F5F5F9",
        grey: "#A1A4B2",
        darkgrey: "#666666"
      },
      postit: "#FFF6A4",
      white: "#FFFFFF",
      black: "#3F414E"
    },
    gradient: {
      primary: {
        main: "linear-gradient(180deg, #7CCACC 0%, #FFFFFF 100%)"
      },
      secondary: {
        main: "linear-gradient(180deg, #EC9477 0%, #FFFFFF 100%)"
      }
    }
  },
  scrollbar: {
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
      backgroundColor: "rgba(255, 255, 255, 0.15)"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(255, 255, 255, 0.15)"
    },
    "&::-webkit-scrollbar-thumb": {
      cursor: "pointer",
      backgroundColor: "#d3d3d3",
      transition: "background-color 160ms cubic-bezier(0.4, 0, 0.2, 1)"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#7CCACC"
    },
    "&::-webkit-scrollbar-corner": {
      backgroundColor: "rgba(0, 0, 0, 0.015)"
    }
  }
});

export default theme;
