import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppBar, Drawer, Toolbar, makeStyles } from "@material-ui/core";
import Button from "./ui/button";
import Menu from "./menu";
import Content from "./content";
import { useAuth } from "../hooks/use-auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh"
  },
  toolbar: {
    color: theme.palette.theming.white
  },
  toolbarName: {
    fontFamily: "Trocchi",
    fontSize: 22
  },
  toolbarUsername: {
    marginLeft: "auto"
  },
  toolbarButton: {
    marginLeft: 20
  },
  container: {
    display: "flex",
    flex: "1 1 100%"
  },
  content: {
    flex: "1 1 100%",
    height: "100%",
    position: "relative",
    backgroundColor: "#fafafa",
    "& > *": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "#fafafa",
      ...theme.scrollbar
    }
  },
  drawer: {
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 240,
      flexShrink: 0
    }
  },
  drawerPaper: {
    position: "static",
    height: "100%",
    width: 240
  },
  drawerDocked: {
    height: "100%"
  }
}));

const Admin = () => {
  const classes = useStyles();
  const { user, logout } = useAuth();
  const drawerClasses = {
    paper: classes.drawerPaper,
    docked: classes.drawerDocked
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <span className={classes.toolbarName}>[Admin] Salmo-check</span>
          <span className={classes.toolbarUsername}>{user.email}</span>
          <Button
            className={classes.toolbarButton}
            color="secondary"
            variant="contained"
            onClick={logout}
          >
            Déconnexion
          </Button>
        </Toolbar>
      </AppBar>
      <Router>
        <div className={classes.container}>
          <nav className={classes.drawer} aria-label="categories">
            <Drawer classes={drawerClasses} variant="permanent" open>
              <Menu classes={classes} />
            </Drawer>
          </nav>
          <div className={classes.content}>
            <Content />
          </div>
        </div>
      </Router>
    </div>
  );
};

export default Admin;
