import * as React from "react";
import { Card as MUICard, CardActions, CardHeader, makeStyles } from "@material-ui/core";
import CustomButton from "./ui/button";
import { slugify } from "../services/utils";

const useStyles = makeStyles(() => {
  return {
    card: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center"
    },
    cardInner: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      overflow: "hidden",
      "&::after": {
        content: "''",
        position: "absolute",
        top: "100%",
        left: 0,
        width: "100%",
        height: 25,
        pointerEvents: "none",
        touchActions: "none"
      }
    },
    cardTitle: {
      backgroundColor: "#f5f5f5",
      textAlign: "center"
    },
    cardActions: {
      padding: "0 15px"
    },
    cardAction: {
      marginLeft: "auto"
    }
  };
});

const Empty = () => null;
const Button = ({ ...props }) => <CustomButton color="primary" variant="contained" {...props} />;

const Card = ({ children, title, actions, ListActions = Empty, actionsProps }) => {
  const classes = useStyles();

  const renderActions = () => (
    <CardActions className={classes.cardActions}>
      {actions.map(({ title, callback, ...props }) => (
        <Button
          key={`button-${slugify(title)}`}
          className={classes.cardAction}
          onClick={callback}
          {...props}
        >
          {title}
        </Button>
      ))}
    </CardActions>
  );

  return (
    <div className={classes.card}>
      <MUICard className={classes.cardInner}>
        {title && <CardHeader className={classes.cardTitle} title={title} />}
        {children}
        {actions && renderActions}
        <ListActions {...actionsProps} />
      </MUICard>
    </div>
  );
};

export default Card;
