import * as React from "react";
import { Box, makeStyles } from "@material-ui/core";
import Button from "./ui/button";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(() => {
  return {
    actionBox: {
      display: "flex",
      alignItems: "center",
      height: 42,
      padding: "0 15px 15px 15px"
    },
    actionButton: {
      justifyContent: "space-between"
    },
    actionIcon: {
      fontSize: 20,
      marginRight: 10
    }
  };
});

const Actions = ({ children, message, route, action, width, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.actionBox}>
      <Button className={classes.actionButton} color="primary" style={{ width }} onClick={action}>
        <AddIcon className={classes.actionIcon} />
        {message}
      </Button>
      {children}
    </Box>
  );
};

export default Actions;
