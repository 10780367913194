import * as React from "react";
import { Suspense } from "react";
import { CircularProgress } from "@material-ui/core";
import Login from "./components/login";
import Admin from "./components/admin";

import { useAuth } from "./hooks/use-auth";

const App = () => {
  const { user } = useAuth();
  const Display = user ? Admin : Login;
  return (
    <Suspense fallback={<CircularProgress />}>
      <Display />
    </Suspense>
  );
};

export default App;
