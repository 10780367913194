import * as React from "react";
import { forwardRef } from "react";
import { CardActions, makeStyles } from "@material-ui/core";
import Modal from "../../modal";

import { useAdminContext } from "../../../hooks/use-admin-context";
import dtreeError from "../../../json/dtree-errors.json";

const defaultText = {
  modal: "Erreur lors de l'upload du XMIND"
};

const useStyles = makeStyles(() => {
  return {
    errorName: {
      fontWeight: "500"
    },
    errorDescription: {
      paddingTop: "5px",
      paddingBottom: "15px"
    },
    descritionLine: {
      padding: "5px 20px",
      fontSize: "14px"
    }
  };
});

const ErrorModal = forwardRef(({ error }, ref) => {
  const { closeModal } = useAdminContext();
  const classes = useStyles();
  const { text, message } = error;

  const cancelRemoval = () => closeModal();

  const actions = [{ title: "Accepter", color: "primary", onClick: () => cancelRemoval() }];

  const errorMessage = dtreeError.find((e) => e.name === text).description;

  return (
    <Modal title={defaultText.modal} actions={actions} ref={ref}>
      <CardActions className={classes.errorName}>{text}</CardActions>
      <CardActions className={classes.errorDescription}>{errorMessage}</CardActions>
      {message.split("\n").map((str, index) => (
        <CardActions className={classes.descritionLine} key={index}>
          {str}
        </CardActions>
      ))}
    </Modal>
  );
});

export default ErrorModal;
