import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Collapse, List, makeStyles } from "@material-ui/core";
import MenuItem from "./menu-item";

import { getNavigation } from "../navigation";

import { useAuth } from "../hooks/use-auth";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const Menu = () => {
  const [open, setOpen] = useState();
  const [selected, setSelected] = useState();
  const classes = useStyles();
  const { pathname } = useLocation();

  const { user } = useAuth();
  const navigation = getNavigation(user.superadmin);

  const handleClick = (slug, menus, sub) => {
    if (menus && menus.length > 0) {
      const [{ slug: menuSlug }] = menus;
      setSelected(menuSlug);
      setOpen(slug);
      return;
    }
    setSelected(slug);
    !sub && setOpen(undefined);
  };

  const handleSubClick = (slug) => handleClick(slug, undefined, true);

  useEffect(() => {
    if (pathname !== "/") setSelected(pathname.replace("/", ""));
  }, [pathname]);

  useEffect(() => {
    if (selected) {
      navigation.forEach((menu) => {
        const { slug, menus = [] } = menu;
        menus.forEach((submenu) => {
          const { slug: subSlug } = submenu;
          if (subSlug === selected) setOpen(slug);
        });
      });
    }
  }, [navigation, selected]);

  const renderSubMenus = (menus, slug) => (
    <Collapse in={open === slug} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {menus.reduce((acc, { title, slug, hidden }) => {
          const NewItem = (
            <MenuItem
              title={title}
              key={title}
              className={classes.nested}
              to={`/${slug}`}
              selected={selected === slug}
              onClick={() => handleSubClick(slug)}
            />
          );
          return !hidden ? [...acc, NewItem] : acc;
        }, [])}
      </List>
    </Collapse>
  );

  return (
    <List>
      {navigation.reduce((acc, { title, menus, slug, hidden }) => {
        const hasMenus = menus && menus.length > 0;
        const NewItem = (
          <Fragment key={title}>
            <MenuItem
              title={title}
              to={`/${hasMenus ? menus[0].slug : slug}`}
              selected={selected === slug}
              onClick={() => handleClick(slug, menus)}
            />
            {hasMenus && renderSubMenus(menus, slug)}
          </Fragment>
        );
        return !hidden ? [...acc, NewItem] : acc;
      }, [])}
    </List>
  );
};

export default Menu;
