import * as React from "react";
import { Box, IconButton, makeStyles, TextField, Tooltip } from "@material-ui/core";
import Actions from "../../list-actions";
import Button from "../../ui/button";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import PublishIcon from "@material-ui/icons/Publish";

import { useAuth } from "../../../hooks/use-auth";

const useStyles = makeStyles(() => {
  return {
    icon: {
      fontSize: 20,
      marginRight: 10
    },
    textfield: {
      padding: "0 16px",
      flex: 1
    },
    enable: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: "0 0 73px"
    },
    tools: {
      display: "flex",
      justifyContent: "center",
      flex: "0 0 128px"
    }
  };
});

const defaultInfo = { author: "", filename: "", file: undefined };

const ListActions = ({ handleChange, add, setAdd, addOAD }) => {
  const classes = useStyles();
  const { user } = useAuth();

  const changeHandler = (event) => {
    const file = event.target.files[0];
    setAdd({ ...add, filename: file.name, file: file, author: user.email });
  };

  const createOAD = async () => (add ? await addOAD(add) : setAdd({ ...defaultInfo }));

  const renderTools = () => (
    <>
      <TextField
        className={classes.textfield}
        placeholder="Nom du fichier"
        value={add.filename}
        onChange={handleChange(add)}
        autoFocus
      />
      <Tooltip title="Téléverser un fichier .xmind">
        <Button color="primary" component="label">
          <PublishIcon /> Téléverser
          <input type="file" name="file" hidden onChange={changeHandler} />
        </Button>
      </Tooltip>
      <Box className={classes.tools}>
        <IconButton color="primary" onClick={() => addOAD(add)}>
          <Tooltip title="Valider">
            <DoneIcon />
          </Tooltip>
        </IconButton>
        <IconButton color="secondary" onClick={() => setAdd(undefined)}>
          <Tooltip title="Annuler">
            <CloseIcon />
          </Tooltip>
        </IconButton>
      </Box>
    </>
  );

  return (
    <Actions message="Ajouter un xmind (OAD)" action={createOAD} children={add && renderTools()} />
  );
};

export default ListActions;
