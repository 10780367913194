const b64DecodeUnicode = (str) => decodeURIComponent(escape(atob(str)));

export const parse = (token) => {
  try {
    const user = JSON.parse(b64DecodeUnicode(token.split(".")[1]));
    return user;
  } catch (e) {
    throw Error("Jeton de sécurité invalide");
  }
};
