import * as React from "react";
import { Switch, Route } from "react-router-dom";

import { useAuth } from "../hooks/use-auth";

import { getNavigation } from "../navigation";

const Content = () => {
  const { user } = useAuth();
  const flattenedNavigation = getNavigation(user.superadmin).reduce(
    (acc, item) => (item.menus ? [...acc, ...item.menus] : [...acc, item]),
    []
  );

  return (
    <Switch>
      {flattenedNavigation.map(({ slug, Module, options }, i) => (
        <Route key={i} path={`/${slug}`}>
          <Module {...options} />
        </Route>
      ))}
    </Switch>
  );
};

export default Content;
