import React from "react";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import { frFR } from "@material-ui/data-grid";
import ReactDOM from "react-dom";
import App from "./app";
import muiTheme from "./theme";

import { AuthProvider } from "./hooks/use-auth";
import { AdminContextProvider } from "./hooks/use-admin-context";

import "./styles/index.css";

const theme = createMuiTheme(muiTheme, frFR);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <AdminContextProvider>
          <App />
        </AdminContextProvider>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
