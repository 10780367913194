import * as React from "react";
import { useState, useEffect } from "react";
import { Checkbox, makeStyles } from "@material-ui/core";

import { useAuth } from "../../hooks/use-auth";
import { useAdminContext } from "../../hooks/use-admin-context";

import { createUser, removeUser, getUserList, updateUserSuperadmin } from "../../services/api";

import Card from "../card";
import List from "../list";
import ListActions from "./list-actions/users";
import RemoveModal from "./common/remove-modal";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(() => {
  return {
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  };
});

const messages = {
  deleteModal: "Supprimer un utilisateur",
  deleteConfirm: "Etes-vous sur de vouloir supprimer cet utilisateur ?",
  deleteSuccess: "Utilisateur supprimé"
};

const Users = () => {
  const [users, setUsers] = useState();
  const [add, setAdd] = useState();
  const classes = useStyles();
  const { user, token } = useAuth();
  const { setLoader, triggerError, triggerSuccess, openModal } = useAdminContext();

  useEffect(() => {
    const get = async () => {
      const result = await getUserList(token);
      setUsers(result);
    };
    get();
    return () => setUsers(undefined);
  }, [token]);

  const updateSuperadmin = async (email, role) => {
    setLoader(true);
    const { user_role } = await updateUserSuperadmin(token, email, role);
    setLoader(false);
    if (user_role === undefined) return triggerError("Une erreur est survenue, veuillez réessayer");
    const newUsers = users.map((user) => {
      const superadmin = user.email === email ? (user_role ? 1 : 0) : user.superadmin;
      return { ...user, superadmin };
    });
    setUsers(newUsers);
  };

  const addUser = async (add) => {
    setLoader(true);
    const { email, superadmin } = add;
    if (!email) {
      setLoader(false);
      return triggerError("L'email est requis.");
    }
    const emails = users.map(({ e }) => e);
    if (emails.includes(email)) {
      setLoader(false);
      return triggerError("Cet utilisateur existe déjà.");
    }
    const { user_id: id } = await createUser(token, email, superadmin);
    if (id) {
      setUsers([...users, { id, email, superadmin: superadmin ? 1 : 0 }]);
      setAdd(undefined);
      triggerSuccess("Utilisateur ajouté.");
      setLoader(false);
    }
  };

  const remove = (id) => async () => {
    const { status } = await removeUser(token, id);
    if (status === 200) {
      setUsers(users.filter((user) => user.id !== id));
      return true;
    }
  };

  const handleChange = (add) => (e) => setAdd({ ...add, email: e.target.value });
  const toggleSuperadmin = (add) => (e) => setAdd({ ...add, superadmin: !add.superadmin });

  const onRemoveButtonClick = (id) =>
    openModal(<RemoveModal remove={remove(id)} messages={messages} />);

  const onCellChange = (email) => (e) => {
    const { checked } = e.target;
    updateSuperadmin(email, checked);
  };

  const renderCell = ({ row }) => {
    const { email, superadmin } = row;
    const onChange = onCellChange(email);
    return (
      <Checkbox
        checked={Boolean(superadmin)}
        onChange={onChange}
        disabled={email === user.email}
        color="primary"
      />
    );
  };

  const columns = [
    { field: "id", hide: true, headerName: "id", width: 70 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "superadmin", headerName: "Superadmin", width: 120, renderCell }
  ];

  const actions = [{ Icon: DeleteIcon, callback: onRemoveButtonClick }];
  const actionsProps = { handleChange, toggleSuperadmin, add, setAdd, addUser };

  return (
    <div className={classes.root}>
      <Card title="Gestion des utilisateurs" ListActions={ListActions} actionsProps={actionsProps}>
        <List loading={users === undefined} columns={columns} rows={users} rowActions={actions} />
      </Card>
    </div>
  );
};

export default Users;
