import * as React from "react";
import { useState, useEffect } from "react";
import { Checkbox, makeStyles } from "@material-ui/core";
import List from "../list";
import Card from "../card";
import RemoveModal from "./common/remove-modal";
import ErrorModal from "./common/error-modal";
import ListActions from "./list-actions/oads";
import DeleteIcon from "@material-ui/icons/Delete";
import LaunchIcon from "@material-ui/icons/Launch";

import { useAuth } from "../../hooks/use-auth";
import { useAdminContext } from "../../hooks/use-admin-context";

import {
  getHistory,
  getActiveOAD,
  uploadOAD,
  activateOAD,
  removeOAD,
  getFrontLink
} from "../../services/api";
import { formatDate } from "../../services/utils";

const useStyles = makeStyles(() => {
  return {
    checkbox: {
      justifySelf: "center"
    }
  };
});

const messages = {
  deleteModal: "Supprimer un OAD",
  deleteConfirm: "Etes-vous sur de vouloir supprimer cet XMind ?",
  deleteSuccess: "XMind supprimé"
};

const OADs = () => {
  const [oadList, setOADList] = useState([]);
  const [add, setAdd] = useState();
  const classes = useStyles();
  const { token } = useAuth();
  const { openModal, triggerError, triggerSuccess, setLoader } = useAdminContext();

  const getOADList = async (token) => {
    const histroy = await getHistory(token);
    const active = await getActiveOAD(token);
    setOADList(
      histroy.map((x) => ({ ...x, datetime: formatDate(x.datetime), enabled: active.id === x.id }))
    );
  };

  useEffect(() => {
    getOADList(token);
    return () => setOADList(undefined);
  }, [token]);

  const update = async ({ id, author }, checked) => {
    if (!checked) return;
    setLoader(true);
    const newOADs = await activateOAD(token, id, author);
    console.log(newOADs);
    getOADList(token);
    setLoader(false);
    triggerSuccess(`Nouveau XMind activé.`);
  };

  const remove = (id) => async () => {
    const newOADs = await removeOAD(token, id);
    console.log(newOADs);
    getOADList(token);
    return true;
  };

  const onRemoveButtonClick = (id) =>
    openModal(<RemoveModal remove={remove(id)} messages={messages} />);
  const onGetLinkButtonClick = (id) => {
    getFrontLink(id).then((link) => window.open(link));
  };

  const renderCell = ({ row }) => {
    const enabled = Boolean(row.enabled);
    const onChange = (_, checked) => update(row, checked);
    return (
      <Checkbox
        className={classes.checkbox}
        checked={enabled}
        onChange={onChange}
        color="primary"
      />
    );
  };

  const addOAD = async (add) => {
    setLoader(true);
    const { author, filename, file } = add;
    const ext = "xmind";
    if (!file) {
      setLoader(false);
      return triggerError("Le fichier xmind n'as pas été chargé correctement.");
    }
    if (file.name.split(".").pop() !== ext) {
      setLoader(false);
      return triggerError("Le format du fichier n'est pas un xmind.");
    }
    if (filename.split(".").pop() !== ext) {
      setLoader(false);
      return triggerError("Le nom du fichier n'a pas l'extension .xmind.");
    }
    const body = new FormData();
    body.append("file", file);
    body.append("author", author);
    body.append("filename", filename);
    try {
      await uploadOAD(token, body);
      getOADList(token);
      triggerSuccess(`Nouveau XMind ajoutée.`);
    } catch (error) {
      if (error.code === 440 || error.code === 441) openModal(<ErrorModal error={error} />);
      else triggerError(`Erreur ${error.code} (${error.text}): ${error.message}`);
    }
    setAdd(undefined);
    setLoader(false);
  };

  const handleChange = (add) => (e) => setAdd({ ...add, filename: e.target.value });

  const columns = [
    { field: "id", hide: true, headerName: "id", width: 70 },
    { field: "display_name", headerName: "Nom du document", flex: 1 },
    { field: "author", headerName: "Auteur", flex: 1 },
    { field: "datetime", headerName: "Date", width: 100 },
    { field: "enabled", headerName: "Activé", width: 73, renderCell }
  ];

  const rowActions = [
    {
      Icon: LaunchIcon,
      callback: onGetLinkButtonClick,
      toolTip: "Lien vers l'interface test OAD."
    },
    { Icon: DeleteIcon, callback: onRemoveButtonClick, toolTip: "Supprime l'OAD." }
  ];

  const actionsProps = { handleChange, add, setAdd, addOAD };

  return (
    <Card title="Gestion des OADs" ListActions={ListActions} actionsProps={actionsProps}>
      <List
        loading={oadList === undefined}
        columns={columns}
        rows={oadList}
        rowActions={rowActions}
      />
    </Card>
  );
};

export default OADs;
