import { get, del, post } from "./fetcher";
import { getConfig, withConfiguration } from "./config";

export const userLogin = async (email, password) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/auth/`;
    return post(endpoint, { email, password });
  });
};

export const userResetPassword = async (email) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/auth/reset-password`;
    return post(endpoint, { email });
  });
};

export const userUpdatePassword = async (email, password, token) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/auth/update-password`;
    return post(endpoint, { email, password }, undefined, {
      Authorization: token
    });
  });
};

export const createUser = async (token, email, superadmin) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/user/create`;
    return post(endpoint, { email, superadmin }, undefined, {
      Authorization: token
    });
  });
};

export const removeUser = async (token, id) => {
  return withConfiguration(async (config) => {
    const endpoint = `${config["api"]}/user/remove`;
    return post(endpoint, { id }, undefined, {
      Authorization: token
    });
  });
};

export const getUserList = async (token) => {
  return withConfiguration(async (config) => {
    const enpoint = `${config["api"]}/user/list`;
    return get(enpoint, undefined, {
      Authorization: token
    });
  });
};

export const updateUserSuperadmin = async (token, email, superadmin) => {
  return withConfiguration(async (config) => {
    const enpoint = `${config["api"]}/user/update-role`;
    return post(enpoint, { email, superadmin }, undefined, {
      Authorization: token
    });
  });
};

export const getHistory = async (token) => {
  return withConfiguration(async (config) => {
    const enpoint = `${config["api"]}/dtree/list`;
    return get(enpoint, undefined, {
      Authorization: token
    });
  });
};

export const getActiveOAD = async (token) => {
  return withConfiguration(async (config) => {
    const enpoint = `${config["api"]}/dtree/id`;
    return get(enpoint, undefined, {
      Authorization: token
    });
  });
};

export const uploadOAD = async (token, body) => {
  return withConfiguration(async (config) => {
    const enpoint = `${config["api"]}/dtree/upload`;
    return post(
      enpoint,
      body,
      undefined,
      {
        Authorization: token
      },
      true
    );
  });
};

export const activateOAD = async (token, id, author) => {
  return withConfiguration(async (config) => {
    const enpoint = `${config["api"]}/dtree/${id}/set`;
    return post(enpoint, { author }, undefined, {
      Authorization: token
    });
  });
};

export const removeOAD = async (token, id) => {
  return withConfiguration(async (config) => {
    const enpoint = `${config["api"]}/dtree/${id}/remove`;
    return del(enpoint, {}, undefined, {
      Authorization: token
    });
  });
};

export const getFrontLink = async (id) => {
  const config = await getConfig();
  return `${config["front"]}/dtree/${id}`;
};
