export const slugify = (str) => {
  let newStr = str.replace(/^\s+|\s+$/g, "");

  newStr = newStr.toLowerCase();

  const from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  const to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    newStr = newStr.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  newStr = newStr
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  const lastChar = newStr[newStr.length - 1];

  newStr = lastChar === "-" ? newStr.slice(0, -1) : newStr;

  return newStr;
};

export const isObject = (variable) =>
  Object.prototype.toString.call(variable) === "[object Object]";

export const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const day = d.getDate();
  const monthNumber = d.getMonth() + 1;
  return `${day}/${monthNumber}/${year}`;
};
