import * as React from "react";
import { Link } from "react-router-dom";
import { ListItem, ListItemText } from "@material-ui/core";

const MenuItem = ({ title, ...props }) => (
  <ListItem button component={Link} to={"/"} {...props}>
    <ListItemText primary={title}></ListItemText>
  </ListItem>
);

export default MenuItem;
