import * as React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

const buildColumnsWithActions = (columns, rowActions, lineAction) => {
  const buildActions = (row) =>
    rowActions.map((action, i) => {
      const { id } = row;
      const { Icon, callback, toolTip } = action;
      const onClick = () => {
        typeof callback === "function" && callback(id);
      };
      return (
        <Tooltip key={i} title={toolTip || ""}>
          <IconButton onClick={onClick}>
            <Icon />
          </IconButton>
        </Tooltip>
      );
    });
  return [
    ...columns.map((props) => ({ disableColumnMenu: true, ...props })),
    {
      field: "",
      headerName: "",
      sortable: false,
      flex: 0,
      width: rowActions.length * 48 + 32,
      disableColumnMenu: true,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => buildActions(row)
    }
  ];
};

const List = ({
  loading,
  width = 800,
  height,
  rows = [],
  columns = [],
  rowActions = [],
  lineAction,
  pageSize = 10
}) => {
  const columnsWithAction =
    rowActions.length > 0 ? buildColumnsWithActions(columns, rowActions, lineAction) : columns;
  return (
    <div style={{ height, width, padding: 15 }}>
      <DataGrid
        rows={rows}
        columns={columnsWithAction}
        loading={loading}
        pageSize={pageSize}
        autoHeight
        disableSelectionOnClick
        onRowClick={lineAction ? ({ row }) => lineAction(row.id) : () => {}}
      />
    </div>
  );
};

export default List;
