import * as React from "react";
import { forwardRef } from "react";
import Modal from "../../modal";

import { useAdminContext } from "../../../hooks/use-admin-context";

const defaultMessages = {
  modal: "Supprimer",
  confirm: "Etes-vous sur de vouloir supprimer cette entrée ?",
  success: "Entrée supprimée"
};

const RemoveModal = forwardRef(({ remove, messages }, ref) => {
  const { closeModal, triggerError, triggerSuccess, setLoader } = useAdminContext();

  const cancelRemoval = () => closeModal();
  const confirmRemoval = async () => {
    setLoader(true);
    try {
      const success = await remove();
      if (success) {
        triggerSuccess(messages.deleteSuccess || defaultMessages.success);
        closeModal();
      }
      setLoader(false);
    } catch (error) {
      const { code = "", message = "Une erreur est survenue, veuillez réessayer" } = error;
      triggerError(`${code} ${message}`);
      setLoader(false);
    }
  };

  const actions = [
    { title: "Annuler", color: "secondary", variant: "text", onClick: () => cancelRemoval() },
    { title: "Confirmer", color: "primary", onClick: () => confirmRemoval() }
  ];

  return (
    <Modal title={messages.deleteModal || defaultMessages.modal} actions={actions} ref={ref}>
      {messages.deleteConfirm || defaultMessages.confirm}
    </Modal>
  );
});

export default RemoveModal;
