import * as React from "react";
import { createContext, useContext } from "react";
import { useLocalStorage } from "./use-local-storage";
import { parse } from "../services/token";
// import { checkAuth } from '../services/api'

const AuthContext = createContext();

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

const AuthProvider = (props) => {
  const [user, setUser] = useLocalStorage("user", undefined, true);
  const [token, setToken] = useLocalStorage("token", undefined, true);

  const login = async (token) => {
    setUser(parse(token));
    setToken(token);
  };

  const logout = () => {
    setToken(undefined);
    setUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        token
      }}
      {...props}
    />
  );
};

export { AuthProvider, useAuth };
