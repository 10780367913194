import * as React from "react";
import { forwardRef } from "react";
import { Card, CardActions, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import Button from "./ui/button";

const useStyles = makeStyles(() => {
  return {
    modalHeader: {
      padding: "25px 25px 0 25px"
    },
    modalInner: {
      display: "flex",
      flexDirection: "column",
      padding: "25px"
    },
    modalButtons: {
      padding: "0 25px 25px 25px"
    },
    modalButton: {
      margin: 0,
      "&:last-child": {
        marginLeft: "auto"
      }
    }
  };
});

export const Modal = forwardRef(({ children, title, actions }, ref) => {
  const classes = useStyles();

  return (
    <Card ref={ref}>
      <CardHeader className={classes.modalHeader} title={title} />
      <CardContent className={classes.modalInner}>{children}</CardContent>
      <CardActions className={classes.modalButtons}>
        {actions.map(({ title, ...props }, index) => (
          <Button // color, variant, disabled, onClick
            key={`card-action-${index}`}
            className={classes.modalButton}
            variant="contained"
            type="submit"
            {...props}
          >
            {title}
          </Button>
        ))}
      </CardActions>
    </Card>
  );
});

export default Modal;
