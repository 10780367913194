import * as React from "react";
import { Box, Checkbox, IconButton, TextField, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Actions from "../../list-actions";

const useStyles = makeStyles(() => {
  return {
    textfield: {
      padding: "0 16px",
      flex: 1
    },
    enable: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: "0 0 84px"
    },
    tools: {
      flex: "0 0 122px"
    }
  };
});

const initialUser = { email: "", superadmin: false };

const ListActions = ({ handleChange, toggleSuperadmin, add, setAdd, addUser }) => {
  const classes = useStyles();

  const createUser = async () => (add ? await addUser(add) : setAdd(initialUser));

  const renderTools = () => (
    <>
      <TextField
        className={classes.textfield}
        autoFocus={true}
        value={add.name}
        onChange={handleChange(add)}
      />
      <Box className={classes.enable}>
        <Checkbox checked={add.superadmin} color="primary" onChange={toggleSuperadmin(add)} />
      </Box>
      <Box className={classes.tools}>
        <IconButton color="primary" onClick={() => addUser(add)}>
          <DoneIcon />
        </IconButton>
        <IconButton color="secondary" onClick={() => setAdd(undefined)}>
          <CloseIcon />
        </IconButton>
      </Box>
    </>
  );

  return (
    <Actions
      message={"Ajouter un utilisateur"}
      action={createUser}
      children={add && renderTools()}
    ></Actions>
  );
};

export default ListActions;
