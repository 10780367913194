import * as React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => {
  return {
    loader: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      userSelect: "none",
      cursor: "progress",
      zIndex: 200000
    }
  };
});

const Loader = () => {
  const classes = useStyles();
  return <div className={classes.loader} />;
};

export default Loader;
