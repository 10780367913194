import * as React from "react";
import { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MUIButton from "@material-ui/core/Button";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  contained: {
    color: theme.palette.theming.white
  }
}));

const Button = forwardRef(({ variant, className, ...props }, ref) => {
  const classes = useStyles();
  const buttonClasses = classnames(className, { [classes.contained]: variant === "contained" });
  return <MUIButton className={buttonClasses} variant={variant} ref={ref} {...props} />;
});

export default Button;
